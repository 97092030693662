import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, useParams, NavLink } from "react-router-dom";
import AuthService from "../utils/auth.service";
import ReactTooltip from "react-tooltip";
import Navbar from "./navbar";
import Footer from "./footer";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const ChiSiamo = (props: any) => {
  const [zoom, setZoom] = useState(155);
  useEffect(() => {
    ReactTooltip.rebuild();
    let size = getWindowDimensions();
    if (size.width < 768) {
      setZoom(100);
    }
    console.log(size.width);

    if (size.width < 1600) {
      setZoom(115);
    }
  }, []);

  return (
    <div>
      <Navbar />
      <div className="main-container">
        <Container className="container">
          <Row style={{ marginTop: 75 }}>
            <object
              style={{
                width: "100%",
                height: "100%",
                minHeight: "100vh",
              }}
              type="application/pdf"
              data={
                "https://listen-marketing.com/PRESENTAZIONE%20LISTEN.pdf?#zoom=" +
                zoom +
                "&scrollbar=0&toolbar=0&navpanes=0"
              }
            >
              <p>Presentazione non disponibile.</p>
            </object>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ChiSiamo;
