import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useHistory, NavLink } from "react-router-dom";
import Navbar from "./navbar";
import Footer from "./footer";
import ReactTooltip from "react-tooltip";
import AuthService from "../utils/auth.service";
import axios from "axios";

const Blog = () => {
  const [articles, setArticles]: any = useState([]);
  const [user, setUser] = useState<any>(null);
  const history = useHistory();

  useEffect(() => {
    ReactTooltip.rebuild();
    const currentUser = AuthService.getCurrentUser();
    if (currentUser) {
      setUser(currentUser);
      fetchArticles();
    }
  }, []);

  const fetchArticles = async () => {
    try {
      const response = await axios.get(
        "https://wordpress.listen-marketing.com/wp-json/wp/v2/posts"
      );
      const posts = response.data;

      // Create a copy of the posts to avoid direct mutation
      const updatedPosts = await Promise.all(
        posts.map(async (post: any) => {
          if (post.featured_media) {
            const mediaResponse = await axios.get(
              `https://wordpress.listen-marketing.com/wp-json/wp/v2/media/${post.featured_media}`
            );
            const media = mediaResponse.data;
            post.featured_image_url = media.source_url;
          } else {
            post.featured_image_url = null; // Set to null if there's no featured media
          }
          return post;
        })
      );

      setArticles(updatedPosts);
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };

  const handleReadMore = (id: any) => {
    history.push(`/blog/${id}`);
  };

  return (
    <div>
      <Navbar />
      <div className="main-container">
        <Container className="container">
          {user ? (
            <>
              <Row style={{ marginTop: 75 }}>
                <Col>
                  <h1>Blog Articles</h1>
                </Col>
              </Row>
              <Row>
                {articles.map((article: any) => (
                  <Col sm={4} key={article.id} style={{ marginBottom: "20px" }}>
                    <div className="article-card">
                      <img
                        src={
                          article.featured_image_url
                            ? article.featured_image_url
                            : "https://via.placeholder.com/750x450"
                        }
                        alt={article.title.rendered}
                        style={{ width: "100%", height: "auto" }}
                      />
                      <h2>{article.title.rendered}</h2>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: article.excerpt.rendered,
                        }}
                      />
                      <button
                        onClick={() => handleReadMore(article.id)}
                        className="btn active"
                      >
                        Leggi l'articolo!
                      </button>
                    </div>
                  </Col>
                ))}
              </Row>
            </>
          ) : (
            <Row style={{ marginTop: 75 }}>
              <Col>
                <h1>
                  Please{" "}
                  <NavLink to="/login" style={{ color: "#5851db" }}>
                    log in
                  </NavLink>{" "}
                  to view the blog articles.
                </h1>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </div>
  );
};

export default Blog;
