import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useParams } from "react-router-dom";
import Navbar from "./navbar";
import Footer from "./footer";
import ReactTooltip from "react-tooltip";
import AuthService from "../utils/auth.service";
import axios from "axios";
import { useHistory, NavLink } from "react-router-dom";

const BlogArticle = () => {
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState<any>(null);

  const [article, setArticle] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    ReactTooltip.rebuild();
    const currentUser = AuthService.getCurrentUser();
    if (currentUser) {
      setUser(currentUser);
      fetchArticle();
    }
  }, []);

  const fetchArticle = async () => {
    try {
      const response = await axios.get(
        `https://wordpress.listen-marketing.com/wp-json/wp/v2/posts/${id}`
      );
      setArticle(response.data);
    } catch (error) {
      console.error("Error fetching article:", error);
    } finally {
      setLoading(false);
    }
  };

  if (user && loading) {
    return (
      <div>
        <Navbar />
        <div className="main-container">
          <Container className="container">
            <Row style={{ marginTop: 75 }}>
              <Col>
                <h2>Loading...</h2>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Navbar />
      <div className="main-container">
        <Container className="container">
          {user ? (
            <Row style={{ marginTop: 75 }}>
              <Col>
                {article ? (
                  <div className="article-content">
                    <h1>{article.title.rendered}</h1>
                    {article.featured_media ? (
                      <img
                        src={article.featured_media}
                        alt={article.title.rendered}
                        style={{
                          width: "100%",
                          height: "auto",
                          marginBottom: "20px",
                        }}
                      />
                    ) : null}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: article.content.rendered,
                      }}
                    />
                  </div>
                ) : (
                  <h2>Article not found</h2>
                )}
              </Col>
            </Row>
          ) : (
            <Row style={{ marginTop: 75 }}>
              <Col>
                <h1>
                  Please{" "}
                  <NavLink to="/login" style={{ color: "#5851db" }}>
                    log in
                  </NavLink>{" "}
                  to view the blog articles.
                </h1>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </div>
  );
};

export default BlogArticle;
